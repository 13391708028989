<template>
  <z-card width="100%" style="height: 100%" :title="$t('guest.search_zipcode')">
    <template #content>
      <div class="d-flex flex-column mb-5">
        <p v-if="hasError" class="error--text mt-3">
          {{ $t("guest.error_search_cep") }}
        </p>
        <z-input
          class="label-adress mt-3 d-flex d-inline-flex"
          :label="$t('guest.address')"
          :placeholder="$t('guest.placeholder_address_example')"
          type="text"
          :value="cepSearch.address"
          :error-messages="getErrorMessages(v$.address)"
          @input="setCepSearch({ address: $event })"
        />
        <z-input
          class="label-adress mt-3 d-flex d-inline-flex"
          :label="$t('guest.city')"
          :placeholder="$t('guest.placeholder_city_example')"
          type="text"
          :value="cepSearch.city"
          :error-messages="getErrorMessages(v$.city)"
          @input="setCepSearch({ city: $event })"
        />
        <z-autocomplete
          class="label-adress mt-3 d-flex d-inline-flex"
          :label="$t('guest.state')"
          :placeholder="$t('guest.placeholder_state_example')"
          type="text"
          :value="cepSearch.uf"
          :items="statesOptions.addressForgetCep"
          item-text="uf"
          item-value="uf"
          :error-messages="getErrorMessages(v$.uf)"
          @change="setCepSearch({ uf: $event })"
        />
      </div>
      <div class="d-flex flex-column">
        <z-btn
          primary
          :is-loading="isLoadingCeps"
          class="mb-3"
          :disabled="v$.$dirty && v$.$invalid"
          :text="$t('guest.search')"
          @click="onClickSearch"
        />
        <z-btn
          :text="$t('guest.back')"
          @click="
            $router.push({
              name: 'personal-info',
              query: 'address',
            })
          "
        />
      </div>
    </template>
  </z-card>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { computed, onMounted } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers"

import { useStoreAction } from "@/composables"

import { usePersonalInfoValidations } from "./useValidations"
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { cepSearch, statesOptions } = useNamespacedState("parameters", [
      "cepSearch",
      "statesOptions",
    ])
    const {
      getCEPFromAddressUsingViaCEP,
      isLoading: isLoadingCeps,
      hasError,
    } = useStoreAction("parameters", "getCEPFromAddressUsingViaCEP")

    const { setCepSearch } = useNamespacedMutations("parameters", [
      "setCepSearch",
    ])

    const { getErrorMessages, searchCepRules } = usePersonalInfoValidations({
      cepSearch,
    })

    const router = useRouter()

    const onClickSearch = async () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        await getCEPFromAddressUsingViaCEP()
        router.push({ name: "forgot-address-results" })
      }
    }

    const rules = computed(() => {
      return {
        ...searchCepRules.value,
      }
    })

    const v$ = useVuelidate(rules, cepSearch)

    onMounted(() => {
      v$.value.$reset()
    })

    return {
      cepSearch,
      getCEPFromAddressUsingViaCEP,
      setCepSearch,
      statesOptions,
      isLoadingCeps,
      hasError,
      getErrorMessages,
      onClickSearch,
      v$,
    }
  },
}
</script>

<style lang="scss" scoped>
.error--text {
  font-size: 0.75rem;
  color: red;
}
</style>
